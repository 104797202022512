<template>
	<div class="userinfo">
		<div class="tilte">
			<div></div>
			<span>{{$t('mine.menuList[0]')}}</span>
		</div>
		<div class="user">
			<div class="avatar">
				<el-avatar :size="100" :src="avatar"></el-avatar>
				<el-upload class="upload-demo" action="https://admin.akomapa.online/common/upload"
					:on-success="handlePreview" :multiple="false" :show-file-list="false">
					<div class="button">
						{{$t('mine.pepol.touxiang')}}
						<!-- //:limit="1" -->
					</div>
				</el-upload>
			</div>
			<div class="name-sex">
				<div class="name">
					<span>{{$t('mine.pepol.necheng')}}</span>
					<el-input v-model="nickName" :placeholder="$t('mine.pepol.plece')"></el-input>
				</div>
				<div class="sex">
					<span>{{$t('mine.pepol.xingbie')}}</span>
					<el-select value-key="label" @change="change" v-model="gender"
						:placeholder="$t('mine.pepol.xuanze')">
						<el-option v-for="item in selcetGender" :key="item.value"
							:label="isChinese() ? item.label:item.labelEng" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="phone">
				<span>{{isChinese() ? $t('mine.pepol.phoen'):$t('footer.emailAdrs')}}</span>
				<el-input v-model="phoneNumber" :placeholder="isChinese() ? $t('mine.pepol.pleasePhoen'):'Please enter your email address'"></el-input>
			</div>
			<div class="phone adrs">
				<span>{{ $t('mine.pepol.adr') }}</span>
				<textarea v-model="address"></textarea>
			</div>
			<div class="after">
				<div class="button" @click="confirm">
					{{$t('mine.pepol.button')}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		updateUser,
		getUserDetail
	} from '@/api/main.js'
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		data() {
			return {
				phoneNumber: '',
				avatar: '',
				nickName: '',
				address: '',
				sex: '',
				gender: '',
				selcetGender: [{
					value: '1',
					label: '男',
					labelEng: 'man'
				}, {
					value: '0',
					label: '女',
					labelEng: 'girl'
				}]
			}
		},
		computed: {
			...mapState(['userInfo'])
		},
		mounted() {
			var {
				avatar,
				phoneNumber,
				nickName,
				address,
				sex,
				eMail
			} = this.userInfo;
			console.log(this.userInfo)
			this.avatar = avatar
			if(this.isChinese()){
				this.phoneNumber = phoneNumber
			}else{
				this.phoneNumber = eMail
			}
			console.log(this.phoneNumber)
			this.nickName = nickName
			this.address = address
			if (this.isChinese()) {
				this.gender = sex == 0 ? '女' : '男';
			} else {
				this.gender = sex == 0 ? 'girl' : 'man';
			}

			this.sex = sex;
		},
		methods: {
			...mapMutations(['saveUserInfo']),
			change(val) {
				this.sex = val
			},
			handlePreview(file) {
				this.avatar = file.url

			},
			confirm() {

				if (this.isChinese()) {
					if (!this.address) {
						this.$message({
							type: 'warning',
							message: '请先输入收货地址'
						});
						return
					}
					// if (!/^1[3456789]\d{9}$/.test(this.phoneNumber)) {
					//     this.$message({
					//         message: '手机号码有误',
					//         type: 'warning'
					//     });
					//     return;
					// }
					if (this.phoneNumber.length != 9) {
						this.$message({
							message: '手机号码有误',
							type: 'warning'
						});
						return;
					}
					if (!this.nickName) {
						this.$message({
							type: 'warning',
							message: '请先输入名称'
						});
						return
					}

					if (!this.avatar) {
						this.$message({
							type: 'warning',
							message: '请先上传头像'
						});
						return
					}


				}
				if (!this.isChinese()) {
					if (!this.address) {
						this.$message({
							type: 'warning',
							message: 'Please enter the shipping address first'
						});
						return
					}
					if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(this.phoneNumber)) {
						this.$message({
							message: 'Incorrect email address',
							type: 'warning'
						});
						return;
					}

					if (!this.nickName) {
						this.$message({
							type: 'warning',
							message: 'Please enter the name first'
						});
						return
					}

					if (!this.avatar) {
						this.$message({
							type: 'warning',
							message: 'Please upload your avatar first'
						});
						return
					}
				}
				let params = {
					nickName: this.nickName,
					avatar: this.avatar,
					address: this.address,
					sex: this.sex,
				}
				if (this.isChinese()) {
					params.phoneNumber = this.phoneNumber
				} else {
					params.eMail = this.phoneNumber
				}
				updateUser(params).then(res => {
					if (res.code == 0) {
						if (this.isChinese()) {
							this.$message({
								type: 'success',
								message: '提交成功'
							});
						} else {
							this.$message({
								type: 'success',
								message: 'Submit successfully'
							});
						}
						this.getUserDetail()
					} else {
						this.showMessage();
					}
				})
			},
			getUserDetail() {
				getUserDetail({}).then(res => {
					this.saveUserInfo(res.data)
				})
			},
		}
	}
</script>
<style lang="scss">
	.userinfo {
		width: 880px;

		.tilte {
			display: flex;
			align-items: center;
			padding: 20px 0px;

			div {
				width: 2px;
				height: 24px;
				background: #333333;
				margin-right: 10px;
			}

			span {
				font-size: 20px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
			}
		}

		.user {
			width: 880px;
			border: 1px solid #DDDDDD;
			padding: 30px;
			user-select: none;

			.avatar {
				display: flex;
				align-items: center;

				.button {
					background: #000000;
					border: 1px solid #000000;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 20px;
					padding: 6px 12px;
					margin-left: 32px;
				}

				.button:hover {
					background: #ffff;
					color: #000000;
					transition: all .2s ease .2s;

				}
			}

			.name-sex,
			.phone {
				padding: 30px 0px;
				display: flex;
				align-items: center;
				justify-content: flex-start;

				.name,
				.sex {
					width: 320px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.el-input {
						width: 240px;
					}

					.el-input.is-active .el-input__inner,
					.el-input__inner:focus {
						border-color: #DDDDDD;
						outline: 0;
					}

					span {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #666666;
					}
				}

				.name {
					margin-right: 60px;
				}
			}

			.phone {
				width: 703px;
				justify-content: space-between;

				.el-input {
					width: 625px;
				}

				.el-input.is-active .el-input__inner,
				.el-input__inner:focus {
					border-color: #DDDDDD;
					outline: 0;
				}

				span {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #666666;
				}

				textarea {
					width: 600px;
					height: 128px;
					border: 1px solid #DDDDDD;
					resize: none;
					padding: 10px;
				}
			}

			.adrs {
				align-items: flex-start;
			}

			.after {
				margin-top: 40px;
			}

			.after>.button {
				background: #000000;
				border: 1px solid #000000;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 20px;
				padding: 12px 0px;
				width: 160px;
				text-align: center;
				cursor: pointer;
			}

			.after>.button:hover {
				background: #ffff;
				color: #000000;
				transition: all .2s ease .2s;

			}
		}
	}
</style>